import React from 'react'
import PropTypes from 'prop-types'

const Success = ({ handleClick }) => {
    const block = 'b-success'

    const exitClick = () => {
        handleClick()
    }

    return (
        <div className={block}>
            <div className={`${block}__container-close`} onClick={exitClick}></div>
            <div className={`${block}__container`}>
                    <div className={`${block}__exit`} onClick={exitClick}><span></span><span></span></div>
                    <div className={`${block}__top`}>{'Спасибо!'}</div>
                    <div className={`${block}__bottom`}>{'Ваша заявка успешно отправлена.'}</div>
                </div>
        </div>
    )
}

Success.propTypes = {
	handleClick: PropTypes.func.isRequired,
}

export default Success