import React, { useEffect, useState } from 'react'

const Cookie = () => {
    const block = 'b-cookie'

    const [isVisible, setIsVisible] = useState(true)

    const handleClick = () => {
        document.cookie = 'cookie=1; path=/;'
        setIsVisible(false)
    }

    if(isVisible) {
        return (
            <div className={block}>
                <div className={`${block}__banner`}>
                    <div className={`${block}__text`}>
                        Используя данный сайт, вы даете <a href="/privacy-policy/">согласие на использование файлов cookie</a>, помогающих нам сделать его удобнее для вас.
                    </div>
                    <div className={`${block}__button`} onClick={handleClick} >Принять все</div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default Cookie
