import React, { useState } from "react"
import { useForm } from "react-hook-form"

import Success from "../Form/success.jsx"

const SubscribeForm = () => {
	const block = "b-subscribe-form"
	const [isOpen, setModal] = useState(false)
	const [formOpen, setFormOpen] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm()

	const url = `/api/subscribe/`

	const onSubmit = () => {
		const form = document.getElementById("subscribe")
		const data = new FormData(form)
		fetch(url, {
			method: "POST",
			body: data,
		})
			.then((answer) => {
				return answer.json()
			})
			.then((res) => {
				if (res.process == "success") {
					reset()
					formCloseHandler()
					successShow()
				}
			})
	}

	const successShow = () => {
		setModal(!isOpen)
		document.getElementsByTagName("body")[0].classList.toggle("hidden")
		document.querySelector('.b-header__opacity').classList.add('_active')
	}

	const successClose = () => {
		setModal(!isOpen)
		document.getElementsByTagName("body")[0].classList.toggle("hidden")
		document.querySelector('.b-header__opacity').classList.remove('_active')
	}

	const formOpenHandler = () => {
		setFormOpen(true)
		document.getElementsByTagName("body")[0].classList.add("hidden")
		document.querySelector('.b-header__opacity').classList.add('_active')
	}

	const formCloseHandler = () => {
		setFormOpen(false)
		document.getElementsByTagName("body")[0].classList.remove("hidden")
		document.querySelector('.b-header__opacity').classList.remove('_active')
	}

	return (
		<div className={block}>
			<span className={`${block}__form-click`} onClick={formOpenHandler}>
				{`Подписаться на наши новости`}
			</span>
			{formOpen ? <div className={`${block}__form-modal`}>
				<div
					className={`${block}__container-close`}
					onClick={formCloseHandler}
				></div>
				<div className={`${block}__container`}>
					<div className={`${block}__exit`} onClick={formCloseHandler}>
						<span></span>
						<span></span>
					</div>
					<div className={`${block}__top`}>
						<div className={`${block}__title`}>
							{"Подписывайтесь на актуальные новости"}
						</div>
						<div className={`${block}__text`}>
							{'Нажимая "Отправить", вы даете согласие на '}
							<a href="#" target="_blank">обработку своих персональных данных</a>
						</div>
					</div>
					<div className={`${block}__form`}>
						<form onSubmit={handleSubmit(onSubmit)} id="subscribe">
							<div className={`${block}__content`}>
								<div className={`${block}__inputs`}>
									<input
										placeholder="Имя, Фамилия*"
										className={`${block}__input ${
											errors.name != undefined
												? "_invalid"
												: ""
										}`}
										{...register("name", {
											required: true,
										})}
									/>
									<input
										placeholder="E-mail*"
										className={`${block}__input ${
											errors.email != undefined
												? "_invalid"
												: ""
										}`}
										{...register("email", {
											required: true,
											pattern:
												/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
										})}
									/>
								</div>
								<div className={`${block}__button`}>
									<input
										className={`${block}__submit`}
										type="submit"
										value="Подписаться"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			:
			null}
			{isOpen && <Success handleClick={successClose} />}
		</div>
	)
}

export default SubscribeForm
