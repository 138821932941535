import React, { useState } from "react"
import { useForm } from 'react-hook-form'
import InputMask from "react-input-mask"

import Success from '../Form/success.jsx'

const ContactsForm = () => {
    const block = 'b-contacts-form'
	const [isOpen, setModal] = useState(false)
	const [pdIsChecked, setPdIsChecked] = useState(false)
	const [odIsChecked, setOdIsChecked] = useState(false)

	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const url = `/api/contacts/`

	const onSubmit = () => {
		const form = document.getElementById('contacts')
		const data = new FormData(form)
		fetch(url, {
			method: 'POST',
			body: data,
		})
		.then((answer) => { return answer.json() })
		.then(res => {
			if(res.process == 'success') {
				reset()
				setOdIsChecked(false)
				setPdIsChecked(false)
				handleClick()
			}
		})
	}

	const handleClick = () => {
		setModal(!isOpen)
		document.getElementsByTagName('body')[0].classList.toggle('hidden')
	}

    return (
		<div className={block} name="contacts">
			<h2 className={`${block}__title`}>
				Задать нам вопрос
			</h2>
			<form id="contacts" onSubmit={handleSubmit(onSubmit)}>
				<div className={`${block}__content`}>
					<div className={`${block}__left`}>
						<input placeholder="Имя, фамилия*" className={`${block}__input ${errors.name != undefined ? "_invalid": ""}`} {...register("name", { required: true })} />
						<input placeholder="Email*" className={`${block}__input ${errors.email != undefined ? "_invalid": ""}`} {...register("email", { required: true, pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
						<InputMask
							className={`${block}__input ${
								errors.phone != undefined
									? "_invalid"
									: ""
							}`}
							mask={"+7 (999) 999-99-99"}
							alwaysShowMask={false}
							placeholder={'Телефон*'}
							type={'text'}
							{...register("phone", { required: true })}
						/>					
					</div>
					<div className={`${block}__right`}>
						<textarea placeholder="Сообщение" className={`${block}__textarea`} {...register("message")} />
					</div>
				</div>
				<div className={`${block}__personal`}>
							<div className={`${block}__checkbox`}>
								<label>
									<span className={`${block}__fake-checkbox
										${errors.pd != undefined ? '_invalid' : ''} 
										${pdIsChecked && '_checked'}`}
									>
									</span>
									<input type="checkbox" className={`${errors.pd != undefined ? '_invalid' : ''}`}
										{...register("pd", {
											required: true,
											onChange: () => setPdIsChecked(!pdIsChecked),
										})}
										checked={pdIsChecked ? true : false}
									/>
								</label>
								<span className={`${block}__checkbox-text`}>
									Я даю согласие на <a href="/agreement/">обработку персональных данных</a>
								</span>
							</div>
							<div className={`${block}__checkbox`}>
								<label>
									<span className={`${block}__fake-checkbox 
										${errors.od != undefined ? '_invalid' : ''}
										${odIsChecked ? '_checked' : ''}`}
									>
									</span>
									<input type="checkbox"
										{...register("od", {
											required: true,
											onChange: () => setOdIsChecked(!odIsChecked),
										})}
										checked={odIsChecked ? true : false}
									/>
								</label>
								<span className={`${block}__checkbox-text`}>
									Я ознакомлен с <a href="/privacy-policy/">политикой обработки данных</a>
								</span>
							</div>
						</div>
				<input type="submit" className={`${block}__submit`} value={'Оставить заявку'} />
			</form>
			{isOpen && <Success handleClick={handleClick} />}
		</div>
	)
}

export default ContactsForm