import ReactDOM from 'react-dom'
import React from 'react'

import Form from './Form/index.jsx'
import ContactsForm from './ContactsForm/index.jsx'
import SubscribeForm from './SubscribeForm/index.jsx'
import Cookie from './Cookie/index.jsx'

document.addEventListener('DOMContentLoaded', () => {
	const form = document.getElementById('feedback')
	const cookie = document.getElementById('cookie')
	const contactsForm = document.getElementById('contacts-form')
	const subscribeForm = document.getElementById('subscribe-form')
 	
	form ? ReactDOM.render(<Form />, form) : null
	contactsForm ? ReactDOM.render(<ContactsForm />, contactsForm) : null
	subscribeForm ? ReactDOM.render(<SubscribeForm />, subscribeForm) : null
	cookie ? ReactDOM.render(<Cookie />, cookie) : null
})
